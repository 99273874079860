@use 'modal_module_nfh_commons' as *;

@include tabletStyles {
  .modal-module-nfh {
    .content-tab {
      padding: 24px 60px 40px;
    }
  }
}

@include commonTabletDesktop {
  .tabs-wrapper {
    ul {
      overflow-x: auto;
    }

    li {
      @include fontSize(14);
      margin: $space3;

      &.current,
      &.active,
      &:hover {
        > a {
          @include interactiveElement;
        }
      }
    }
  }
}
