@use 'segmented_messages_banner_commons' as *;

@include desktopStyles {
  .segmented-messages-banner {
    .newsletter-image {
      min-width: 559px;
    }

    .newsletter-text {
      br {
        content: ' ';
        display: block;
        margin-bottom: 5px;
      }
    }

    .my-iberostar-page & {
      padding-left: 24px;
    }
  }
}

@include tabletStyles {
  .segmented-messages-banner {
    .newsletter-image {
      min-width: 50%;
    }
  }
}
