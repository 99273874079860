@use './hotel_collapsible_commons' as *;

.hotel-collapsible {
  padding: 50px 0;

  &__summary {
    &-title {
      @include fontSize(18);
    }

    &-description {
      @include fontSize(18);
    }

    &-button {
      width: 40px;
      height: 40px;
    }
  }

  &:hover {
    background: var(--interactive-background-hover);

    .hotel-collapsible__summary-button {
      background: var(--swiper-theme-color);
      color: var(--c_white);
    }
  }

  &:has(.hotel-collapsible__summary-header--active) {
    background-color: transparent;
  }

  &__content-header {
    margin-bottom: 50px;
    align-items: center;
  }

  &--woc {
    &:hover {
      background: var(--background-color);

      .hotel-collapsible__summary-button {
        background: var(--background-color);
      }
    }
  }
}
