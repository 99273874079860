@use 'information_horizontal_module_commons' as *;
@use '../../shared/elements/embedded_video/embedded_video';

@include desktopStyles {
  .horizontal-card.info.information-horizontal-module-item .card-image {
    height: 370px;
    max-height: 370px;
  }
}

@include commonTabletDesktop {
  .information-horizontal-module {
    .information-horizontal-module-li {
      &:nth-child(even) {
        .horizontal-card {
          &.info {
            &.information-horizontal-module-item {
              .card-image {
                order: 1;
                margin-left: 0;
                margin-right: 20px;
              }
            }
          }
        }
      }
    }

    .video-content {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@include media($tablet_landscape) {
  .horizontal-card.info {
    .card-image {
      // width: calc('#{flex-grid(4.5)} - 5px');
    }

    .card-container {
      // width: calc('#{flex-grid(7.5)} + 5px')
    }

    &.information-horizontal-module-item {
      height: 400px;

      .card-image,
      .card-container {
        flex: 0 0 320px;
        width: auto;
      }
    }
  }
}

@include media($tablet) {
  .horizontal-card.info {
    .card-image {
      // width: calc('#{flex-grid(5.8)} - 5px');
      left: 0;
      max-height: 283px;
    }

    .card-container {
      // width: calc('#{flex-grid(6.2)} + 5px');
      height: 283px;
    }

    &.information-horizontal-module-item {
      .card-image,
      .card-container {
        width: auto;
      }
    }
  }
}
