@use 'faqs_commons' as *;

@include tabletStyles {
  .with-call-center {
    .faqs-block {
      width: 100%;

      .wrapper {
        padding: 0;
      }
    }
  }
}
