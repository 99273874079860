@use 'best_offers_block_commons' as *;
@use '../../elements/scrollsnap_slider/scrollsnap_slider_commons';

@include commonTabletDesktop {
  .best-offers-block {
    .e-skeleton-content {
      .bg-mask {
        &.row1 {
          margin-top: 22px;
        }

        &.row2 {
          height: 36px;
          margin-top: 22px;
        }

        &.row3 {
          width: 78%;
          height: 22px;
        }

        &.row4 {
          width: 70%;
          height: 26px;
        }

        &.row5 {
          width: 40%;
          height: 22px;
          margin-left: 30%;
        }

        &.row-thin-separator {
          height: 3px;
        }
      }
    }
  }
}

@include desktopStyles {
  .best-offers-block {
    $bg_green_gap: 7px;
    $image_height: 195px;

    .flex {
      padding: 0 20px;

      > * {
        flex: 0 0 flex-grid(3);
        width: flex-grid(3);
      }
    }

    .offers-cnt {
      padding: 0 0 24px;

      &::after {
        height: calc(100% - #{$bg_green_gap} - #{$image_height});
      }
    }

    .content {
      min-height: 205px;
    }

    .image {
      min-height: 170px;
    }
  }
}

@include tabletStyles {
  .best-offers-block {
    overflow: hidden;

    .flex {
      > * {
        height: auto;
      }
    }

    .content {
      min-height: 205px;
    }

    .image {
      min-height: 208px;
    }

    .ss-slider {
      @include scrollsnap_slider_commons.ss-slider;
    }
  }
}
