@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.quote-form-thankyou {
  margin: 50px auto;
  text-align: center;

  .h2 {
    color: var(--c_blue_700);
  }

  .desc {
    margin-bottom: 30px;
    color: var(--c_dark_grey);
  }

  &::before {
    content: '';
    display: block;
    width: 133px;
    height: 110px;
    margin: 20px auto;
    background: url('../../../images/icons/meetings_thankyou.png') no-repeat center;
  }
}
