@use 'information_modules_commons' as *;

.information-modules {
  &__slide {
    .info-item {
      width: 100%;
    }
  }

  &__block {
    .info-item {
      flex: 0 0 calc(100% / 3) ;
      width: calc(100% / 3) ;
    }

  }
}
