@use 'information_module_item_commons' as *;
@use '../../../shared/elements/embedded_video/embedded_video';

.information-modules {
  .info-items {
    .wrapper {
      display: flex;
      align-items: stretch;
      padding: 0;

      .info-item.utag-information-module {
        display: flex;
        height: auto;
      }

    }
  }
}
