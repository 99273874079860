@use 'hotels_filter_map_commons' as *;

@include commonTabletDesktop {
  #hotels-filter-map {
    display: none;
    float: right;
    width: calc(100% - 10px);
    height: 518px;
    margin: 0 0 30px;
  }

  .loading-map {
    position: absolute;
    z-index: 99999;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 518px;
    margin: 0 0 30px;
    background-color: var(--c_white);

    .spinner.spinner-sun > svg {
      width: 80px;
      height: 80px;
    }
  }
}
