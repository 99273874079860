@use 'info_item_commons' as *;

@include commonTabletDesktop {
  .info-item{
    width: calc(100% / 3) ;
    margin-right: $gutter;

    &:last-child {
      margin-right: 0;
    }
  }

  .swiper-pagination {
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    white-space: nowrap;

  }
}

@include desktopStyles {
  .info-item {
    .content {
      padding: 24px;
    }

    .text {
      margin-bottom: 24px;
    }

    .t-highlight {
      margin-bottom: 8px;
    }
  }
}

@include tabletStyles {
  .info-items {
    padding: 0 20px;
    overflow: hidden;

    .wrapper {
      padding: 0;
    }
  }

  .info-item {
    .content {
      padding: 20px;
    }

    .text {
      margin-bottom: 10px;
    }

    .t-highlight {
      margin-bottom: 10px;
    }
  }
}
