@use 'share_offer_commons' as *;

@include commonTabletDesktop {
  .share-offer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background: var(--c_blue_green_100);

    .action {
      padding: 8px 0;
      text-align: center;
    }

    .copy {
      position: relative;
      display: inline-block;
      margin-right: 40px;
      color: var(--c_blue_700);
      font: var(--sans-semi-bold) 18px var(--font-sans);

      &::after {
        position: absolute;
        top: -8px;
        left: -52px;
        font-size: 42px;
      }
    }

    .shared {
      display: inline-block;
      font-size: 14px;

      &::after {
        width: 63px;
        height: 63px;
        margin-left: 10px;
        font-size: 24px;
        line-height: 63px;
      }
    }
  }
}
