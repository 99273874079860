@use 'video_module_commons' as *;

@include desktopStyles {
  .video-module {
    &:hover {
      transform: scale(1.07) translate3d(0, 0, 0);

      .video-link {
        .text {
          opacity: 1;
        }
      }

      img {
        opacity: 0.15;
      }
    }
  }

  .large-screens {
    max-width: 1200px;
  }
}

@include tabletStyles {
  .video-module .cover {
    .video-link {
      top: calc(50% + 10px);
    }
  }
}

// If any specific rule for accessibility is needed, you can insert it below.
.ada {
  .video-link {
    &::before {
      padding: 10px;
      border-radius: 50%;
      background: var(--c_primary);
    }

    .text {
      padding: 3px;
      background: var(--c_primary);
    }
  }
}
