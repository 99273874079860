@use '../../../src/base_css/abstracts/abstracts' as *;

@include commonStyles {
  .filter-header {
    display: flex;
    justify-content: flex-end;

    .destination-index-tags {
      flex: 0 1 100%;
    }

    .total-hotels-counter {
      opacity: 0.8;
      flex: 0 0 auto;
      padding-top: 3px;
    }
  }
}

@include tabletStyles {
  .filter-header {
    .total-hotels-counter {
      @include fontSize(14);
    }
  }
}
