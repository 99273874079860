@use 'special_landing_highlight_commons' as *;

.special-landing-highlight {
  &__title {
    @include fontSize(32);
  }
}

.vertical-animation {
  &__item {
    @include fontSize(32);
  }
}
