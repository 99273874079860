@use 'destination_index_tabs_nav_commons' as *;

@include tabletStyles {
  .destination-index-tabs-nav {
    li {
      > span {
        @include fontSize(12);
        padding-left: 23px;

        &::before {
          width: 17px;
          height: 17px;
        }

        &.active::after {
          width: 11px;
          height: 11px;
        }
      }
    }
  }
}
