@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.quote-form {
  position: relative;

  &.flex {
    align-items: flex-start;
  }

  .quote-form-info {
    @include fontSansBold;
    @include fontSize(12);
    margin-bottom: 42px;
    line-height: 18px;
  }

  .quote-form-menu {
    display: flex;

    li {
      position: relative;
      padding: 15px 32px;
      border: 1px solid var(--c_grey_200);
      border-bottom: 0;
      text-align: center;
      text-transform: uppercase;

      &:first-child {
        border-right: 0;
      }

      &.current {
        position: relative;
        border-top: 2px solid var(--c_blue_700);
        color: var(--c_blue_700);

        a {
          color: var(--c_blue_700);
        }

        &::after {
          position: absolute;
          left: 0;
          bottom: -2px;
          right: 0;
          z-index: 1;
          content: '';
          display: block;
          height: 5px;
          background: var(--c_white);
        }
      }

      a {
        @include link-base;
        text-transform: uppercase;

        &::before {
          display: none;
        }
      }
    }
  }

  .quote-form-wrapper {
    width: calc(67% - 11px);
    margin-right: 11px;
  }

  .chosen-container {
    appearance: none;
    cursor: pointer;
    width: 100% !important;
    padding: 0 25px 0 0;
    border: 0;
    outline: 0;
    background: transparent url('../../../images/new/arrow_formz_select.svg') calc(100% - 10px) calc(100% - 15px) no-repeat;
  }

  .quote-desc {
    margin: 32px 0 40px;
  }

  .form-title {
    margin-bottom: 16px;
    color: var(--c_blue_700);
  }

  .fz-step {
    padding: 15px 20px 0;
    border: 1px solid var(--c_grey_200);
    background: var(--c_white);
  }

  .fz-field {
    border-color: var(--c_grey_200);


  }

  input[type='file'] + label {
    top: -1px;
    bottom: -1px;
    right: -1px;
    width: auto;
    line-height: 40px;
  }

  input[type='text'].is-date {
    padding-left: 36px;
  }

  hr {
    margin: 25px 0;
    background: var(--c_grey_200);
  }

  .overflow-allowed {
    display: flex;
    overflow: visible;
  }

  .form-separator {
    &::before,
    &::after {
      content: '';
      display: inline-block;
      width: 47%;
      border-top: dashed 0.5px var(--c_grey_400);
      vertical-align: middle;
    }

    &::before {
      margin-right: 10px;
    }

    &::after {
      margin-left: 10px;
    }
  }

  .event-wrapper {
    position: relative;
    padding: 10px 10px 0;
    margin-bottom: 20px;
    border: solid 1px var(--c_grey_200);

    .event-button-remove {
      @include icon(close-fat, after, 'xs') {
        position: absolute;
        top: -15px;
        right: -15px;
        z-index: 2;
        cursor: pointer;
        display: block;
        width: 30px;
        height: 30px;
        border: solid 1px var(--c_grey_200);
        border-radius: 50%;
        background-color: white;
        color: var(--c_blue_700);
        line-height: 30px;
        text-align: center;
      }
      z-index: 2;
      display: none;
    }

    .fz-col {
      &.tiny,
      &.small-mobile + .small {
        margin-left: 10px;
      }
    }

    &.removable {
      .event-button-remove {
        display: block;
      }
    }
  }

  .event-button {
    @include fontSize(14);
    appearance: none;
    cursor: pointer;
    margin-top: 20px;
    border: none;
    background: none;
    color: var(--c_primary);
    font-weight: var(--sans-bold);
    font-family: var(--font-sans);
    line-height: 20px;
    text-decoration: none;

    &::after {
      content: '+';
      display: inline-block;
      width: 27px;
      height: 27px;
      padding-top: 3px;
      margin-left: 10px;
      border: solid 1px var(--c_grey_200);
      border-radius: 50%;
      background-color: white;
      color: var(--c_primary);
      font-size: 14px;
      line-height: 17px;
    }

    &.disabled {
      cursor: initial;
      opacity: 0.5;
    }
  }

  //
  // Override formz styles
  //
  p.fz-section-title {
    // @include h6(var(--font-serif));
  }

  .formz {
    input[type='text'].is-date {
      background-image: none;
    }
  }

  .fz-buttons {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;

    .fz-row {
      max-width: fit-content;
    }
  }

  .fz-col {
    &.wide {
      width: 100%;
    }

    &.small,
    &.small-mobile {
      width: 36%;
    }

    &.tiny {
      width: 11%;

      & + .fz-col {
        margin-left: 10px;
      }
    }
  }

  .fz-collapsable.fz-collapsed {
    height: 0;
  }

  .date-field {
    @include icon(calendar, after, 's') {
      position: absolute;
      left: 12px;
      bottom: 13px;
      color: var(--c_primary);
    }
  }

  .fz-section-title {
    @include fontSansSemiBold;
    @include fontSize(18);
    margin-top: 0;
    line-height: 26px;
  }

  .intl-tel-input {
    position: static;

    &.separate-dial-code {
      &.allow-dropdown {
        &.iti-sdc-3 {
          .selected-flag {
            width: 67px;
            padding: 0;
            background-color: rgba(var(--c_cloud_rgb), 0.4);

            .iti-arrow {
              @include icon(arrow-up-down, after, 's') {
                display: block;
                color: var(--c_primary);
              }
              right: 8px;
              transform: translateY(-50%);
              width: 13px;
              height: 15px;
              margin-top: 0;
              border: none;
            }
          }
        }
      }
    }

    &.separate-dial-code .selected-dial-code {
      padding-left: 2px;
    }

    input[type='text'] {
      padding-left: 10px !important;
    }
  }

  .fz-field.w-phone-prefix {
    position: relative;
    padding-left: 80px;
  }

  //
  // Sidebar
  //
  .hotel-selection {
    display: inline-block;
    width: calc(33% - 9px);
    margin-left: 9px;
    border: solid 1px var(--c_grey_200);
    background-color: white;
    vertical-align: top;

    p {
      margin: 0;
    }

    .selection-title {
      @include fontSansSemiBold;
      @include fontSize(12);
      padding: 9px 20px 8px;
      margin: 0;
      border-bottom: solid 1px var(--c_grey_200);
      background-color: rgba(var(--c_cloud_rgb), 0.4);
      line-height: 18px;
      letter-spacing: 1.54px;
      text-transform: uppercase;
    }

    .selection-item {
      padding: 19px 20px 20px;
      border-bottom: solid 1px var(--c_grey_200);
      color: var(--c_blue_700);

      p {
        @include fontSansSemiBold;
        @include fontSize(18);
        display: inline;
        margin-right: 5px;
        color: var(--c_dark_grey);
        line-height: 26px;
      }

      .stars {
        position: relative;
        top: -2px;
        display: inline-block;
      }
    }

    .rating-stars {
      display: block;
      height: 14px;
      font-size: 14px;

      &::after {
        display: block;
      }
    }

    .selection-bottom {
      display: table;
      width: 100%;
      padding: 13px 20px;
      background-color: rgba(var(--c_cloud_rgb), 0.4);
      table-layout: fixed;

      .info {
        @include fontSize(14);
        display: table-cell;
        line-height: 24px;
        vertical-align: top;

        li,
        span {
          display: block;
          color: var(--c_black);
        }
      }
    }

    &.sticky {
      position: fixed;
      top: 0;
    }

    &.sticky-bottom {
      top: auto;
      bottom: 96px;
    }
  }

  // chosen styles
  .chosen-container {
    input[type='text'] {
      @include fontSize(16);
      padding-right: 0;
      line-height: 24px;
    }
  }

  .chosen-choices {
    cursor: pointer;
    padding: 0;
    border: 0;
    box-shadow: 0 0 0 0;
    background: var(--c_white);

    > li {
      position: relative;
      float: none;
      padding: 0;
      padding: 9px 10px 8px 50px;
      color: #8B9199;
      font-size: 18px;
      font-size: 1.125rem;
      font-family: var(--font-sans);
      line-height: 22px;

      &::after {
        position: absolute;
        top: 10px;
        right: 30px;
        z-index: 1;
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        background-image: url('../../../images/interface/green-check.svg');
      }

      &.search-choice {
        position: relative;
        padding: 15px;
        margin: 0;
        border: 0;
        border-radius: 0;
        background: #ECF6FD;
        background-image: unset;
        color: #8B9199;

        &:hover {
          background: var(--c_white);
        }

        .search-choice-close {
          position: absolute;
          inset: 0;
          z-index: 2;
          width: 100%;
          height: unset;
        }
      }

      &.search-field {
        &::after {
          display: none;
        }

        input[type='text'] {
          cursor: pointer;
          color: transparent;
        }
      }
    }
  }

  .counter {
    position: absolute;
    bottom: 0;
    cursor: pointer;
    padding: 0 0 5px 10px;
    margin: 0;
    background: var(--c_white);
    font-size: 18px;
  }

  .chosen-results {
    min-width: 0;
    margin-top: 0;
    overflow: auto;
    box-shadow: 0 0 0 0;

    > li {
      position: relative;

      &::after {
        position: absolute;
        top: 10px;
        right: 15px;
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border: 1px solid #979797;
        border-radius: 4px;
      }

      &.active-result {
        padding: 10px;
      }

      &.result-selected {
        display: none;

        &.hidden {
          display: none;
        }

        &::after {
          border: 0;
          background-image: url('../../../images/interface/green-check.svg');
        }
      }

      &.destinations,
      &.hotels {
        border-bottom: 1px solid #979797;

        &::after {
          border: 0;
        }
      }
    }
  }

  .chosen-drop {
    margin: 28px 0 0;
    border-radius: 10px;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 31%);
    background: var(--c_white);

    &::after {
      position: absolute;
      left: 88%;
      bottom: 100%;
      content: ' ';
      width: 0;
      height: 0;
      margin-left: -10px;
      border: solid transparent;
      border-color: rgba(255, 255, 255, 0%);
      border-width: 10px;
      border-bottom-color: var(--c_white);
      pointer-events: none;
    }
  }

  .first-time {
    .chosen-choices {
      display: none;
    }

    .result-selected {
      display: block;
      padding: 15px;
    }

    .chosen-results {
      .result-selected {
        &.hidden {
          display: block;
        }
      }
    }
  }

  .confirm-box {
    .secondary-btn {
      margin-right: 12px;
    }

    .clear-all {
      @include fontSize(14);
      cursor: pointer;
      margin-right: 10px;
      color: var(--c_primary);
      font-weight: var(--sans-bold);
      font-family: var(--font-sans);
      line-height: 20px;
      text-decoration: none;
    }
  }

  #meeting_rooms_budget_range_id__chosen,
  #hotel_specialty_id__chosen {
    .chosen-choices > li::after {
      right: 15px;
    }
  }
}

@include commonTabletMobile {
  .quote-form {
    &.flex {
      display: flex;
      flex-wrap: wrap;

      .quote-form-wrapper {
        flex: 0 0 100%;
        order: 1;
        width: 100%;
      }

      .hotel-selection {
        flex: 0 1 100%;
        order: 0;
        width: 100%;
        margin-left: 0;
        margin-bottom: 40px;
      }
    }
  }
}
