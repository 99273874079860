@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.discounts-fancybox {
  .fancybox-close-small {
    width: 25px;
    height: 20px;

    &::before {
      font-size: 16px;
    }
  }

  .block-title {
    max-width: 700px;
    margin-bottom: 10px;
  }

  &__top {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--c_grey_200);

    .offer-dates-legend {
      text-align: left;
    }
  }

  &__bottom {
    .info-discount {
      @include icon(check, 'before', 'xs') {
        position: absolute;
        top: 8px;
        padding-right: 8px;
        color: var(--c_green);
      }
      position: relative;
      padding-bottom: 15px;
    }

    .desc-title {
      @include fontSansBold;
      display: inline-block;
      margin-left: 20px;
      color: var(--c_black_grey_700);
    }

    .desc-text {
      padding-left: 20px;

    }

    .desc-text-horizons {
      max-width: 700px;

    }
  }
}

@include commonTabletDesktop {
  .info-resident-fancybox {
    .description-text {

    }
  }

  .info-discount {
    max-width: 700px;
    margin-bottom: 4px;
  }

  .discount {
    &__indent {
      margin-left: 50px;
    }
  }
}
