@use 'offer_block_commons' as *;

@include desktopStyles {
  .offer-block {
    flex: 0 0 calc(50% - 12px);
    width: calc(50% - 12px);
    margin-bottom: 24px;

    .card-image {
      flex: 0 0 34.015%;
      min-height: 300px;
    }

    .container {
      padding: 24px 4.097%;

      .pre-title {
        margin-bottom: 5px;
      }

      .title {
        margin-bottom: 5px;
      }

      .bottom-content {
        .price-info {
          text-align: right;

          .type {
            @include fontSansSemiBold;
            @include fontSize(14);
            display: block;
            color: var(--c_blue_700);
            line-height: 19px;
            letter-spacing: 1.8px;
            text-transform: uppercase;
          }

          .t-price,
          .t-coin {
            color: var(--c_blue_700);
          }

          .highlight {
            .t-price {
              @include fontSize(30);
              line-height: 41px;
            }

            .t-coin {
              @include fontSize(24);
              line-height: 33px;
            }
          }

          .prev-highlight {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .ada {
    .offer-block {
      flex: 0 0 calc(50% - 12px);
      width: calc(50% - 12px);
      margin-bottom: 24px;

      .card-image {
        flex: 0 0 34.015%;
        min-height: 300px;
      }

      .container {
        padding: 24px 4.097%;

        .pre-title {
          margin-bottom: 5px;
        }

        .title {
          margin-bottom: 5px;
        }

        .bottom-content {
          .price-info {
            text-align: right;

            .type {
              @include fontSansSemiBold;
              @include fontSize(14);
              display: block;
              color: var(--c_blue_700);
              line-height: 19px;
              letter-spacing: 1.8px;
              text-transform: uppercase;
            }

            .t-price,
            .t-coin {
              color: var(--c_blue_700);
            }

            .highlight {
              .t-price {
                @include fontSize(30);
                line-height: 41px;
              }

              .t-coin {
                @include fontSize(24);
                line-height: 33px;
              }
            }

            .prev-highlight {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

@include tabletStyles {
  .offer-block {
    flex: 0 0 calc(50% - 12px);
    margin-bottom: 24px;

    .card-image {
      flex: 0 0 23.124%;
    }

    .container {
      padding: 10px;

      .pre-title {
        margin-bottom: 2px;
      }

      .title {
        @include fontSize(14);
        margin-bottom: 8px;
        line-height: 21px;
      }

      .pre-title {
        @include fontSize(10);
        line-height: 18px;
        letter-spacing: 1.29px;
      }

      .hide-on-mobile {
        @include fontSize(14);
        display: none;
      }

      .bottom-content {
        .price-info {
          .type,
          .highlight {
            display: inline-block;
          }

          .t-coin,
          .t-price,
          .type {
            @include fontSansSemiBold;
            @include fontSize(16);
            color: var(--c_blue_700);
            line-height: 22px;
            text-transform: uppercase;
          }
        }
      }
    }

    .fake-link {
      display: none;
    }
  }

  .ada {
    .offer-block {
      flex: 0 0 calc(50% - 12px);
      margin-bottom: 24px;

      .card-image {
        flex: 0 0 23.124%;
      }

      .container {
        padding: 10px;

        .pre-title {
          margin-bottom: 2px;
        }

        .title {
          @include fontSize(14);
          margin-bottom: 8px;
          line-height: 21px;
        }

        .pre-title {
          @include fontSize(10);
          line-height: 18px;
          letter-spacing: 1.29px;
        }

        .hide-on-mobile {
          @include fontSize(14);
          display: none;
        }

        .bottom-content {
          .price-info {
            .type,
            .highlight {
              display: inline-block;
            }

            .t-coin,
            .t-price,
            .type {
              @include fontSansSemiBold;
              @include fontSize(16);
              color: var(--c_blue_700);
              line-height: 22px;
              text-transform: uppercase;
            }
          }
        }
      }

      .fake-link {
        display: none;
      }
    }
  }
}
