@use 'message_hotels_not_found_commons' as *;

@include commonTabletDesktop {
  .message-hotels-not-found {
    padding-top: 130px;
    margin: 80px auto 0;

    &::after {
      font-size: 91px;
    }

    .message-title {
      @include fontSize(32);
      margin-bottom: 20px;
    }
  }
}
