@use 'block_title_header_commons' as *;

@include commonTabletDesktop {
  .block-title-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    > * {
      flex: 0 1 auto;
      align-self: flex-end;
    }

    a {
      line-height: 32px;
    }

    .t-label {
      margin-bottom: 4px;
    }
  }
}
