@use 'offers_index_with_filter_commons' as *;

@include desktopStyles {
  .offers-index-with-filter {
    .mobile-offers-filter-link {
      display: none;
    }
  }
}

@include tabletStyles {
  .offers-index-with-filter {
    .mobile-offers-filter-link {
      display: none;
    }
  }
}
