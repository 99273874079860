@use 'filters_sidebar_commons' as *;
@use '../../elements/destination_index_tabs_nav/destination_index_tabs_nav';

@include commonTabletDesktop {
  .filters-sidebar {
    .filters-block {
      .title {
        &:not(.active):hover {
          background-color: var(--c_blue_green_100);
          color: var(--c_primary);

          span {
            color: var(--c_primary);
          }

          &::before {
            opacity: 1;
          }
        }
      }

      &.active {
        position: relative;

        &::after {
          position: absolute;
          top: -1px;
          left: -1px;
          z-index: 1;
          content: '';
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          border: 1px solid var(--c_primary);
        }
      }
    }
  }
}

@include tabletStyles {
  .filters-sidebar {
    .filters-block {
      .title {
        @include fontSize(14);
        padding: 10px 20px 10px 10px;

        &::after {
          top: 16px;
          right: 10px;
        }

        span {
          font-size: 10px;
        }
      }

      .filters,
      .filters-index {
        padding: 10px;

        > .t-label {
          margin-bottom: 5px;

          &:first-child {
            @include fontSize(12);
          }
        }
      }
    }

    &.formikation {
      label {
        @include fontSize(12);

        &.fk-check {
          padding-left: 23px;
          margin-bottom: 5px;

          &::before {
            top: 4px;
            width: 15px;
            height: 15px;
          }

          &.checked {
            &::after {
              top: 8px;
              left: 3px;
              font-size: 9px;
            }
          }
        }
      }
    }

    .bottom-actions {
      margin-top: 10px;

      .clear-all {
        font-size: 12px;

        &::after {
          font-size: 14px;
        }
      }
    }
  }
}
