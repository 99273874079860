@use 'destinations_index_commons' as *;

.destination-index-container {
  .destination-header-collapsable {
    &__summary {
      &-header {
        &--counter {
          line-height: 65px;
        }
      }
    }
  }

  .destination-list {
    &__items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
    }
  }
}
